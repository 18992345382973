import React, { Component } from 'react';

export default class PrivacyPolicyPage extends Component {
	render() {
		return <div className="page-privacy">
			<div className="panel-standard">
				<div className="container">
					<div className="row">
						<h2>S4YCOMIC.COM PRIVACY NOTICE</h2>
						<h3>Effective September 18, 2019.</h3>
						<p>s4ycomic.com (“S4YCOMIC”) welcomes you to enjoy our services and games (collectively, the “S4YCOMIC Games”) available through s4ycomic.com (the “Website”) and social networking websites, such as Facebook (collectively, “SN Websites”). Our S4YCOMIC Games include, but not limited to, “Speak4Yourself” (currently accessible at http://s4ycomic.com). This Privacy Notice applies to data collected by S4YCOMIC through your use of the S4YCOMIC Games and any other such service provided by S4YCOMIC through the S4YCOMIC Games (collectively, the “Service”).</p>
						<p>Please contact us if you have any questions or comments about our privacy practices or this Privacy Notice. You can reach us online at <a rel="noopener noreferrer" href="mailto:admin@s4ycomic.com">admin@s4ycomic.com</a>.</p>
						<p>S4YCOMIC MAY MODIFY THIS PRIVACY NOTICE AT ANY TIME, IN S4YCOMIC’S SOLE DISCRETION, AND SUCH MODIFICATION SHALL BE EFFECTIVE IMMEDIATELY UPON EITHER POSTING OF THE MODIFIED PRIVACY NOTICE ON THE APPLICATION OR NOTIFYING YOU. YOU AGREE TO REVIEW THIS PRIVACY NOTICE PERIODICALLY TO ENSURE THAT YOU ARE AWARE OF ANY MODIFICATIONS. YOUR CONTINUED ACCESS OR USE OF THE APPLICATION AND/OR THE SOFTWARE SHALL BE DEEMED YOUR CONCLUSIVE ACCEPTANCE OF THE MODIFIED PRIVACY NOTICE.</p>
						<p>Collection of Your Personal Information</p>
						<p>In using or accessing the S4YCOMIC Games, you will not be required to directly provide us with any personal information as we use the information you have already provided to the SN Website (e.g, Facebook) in registering for an account with such SN Website and which you have allowed such SN Website to share with S4YCOMIC, including, but not limited to, your first name, your user profile picture, your friend's list, pages you are a fan of, your gender, and the networks to which you belong (collectively, your “Submitted Information”). In addition to the Submitted Information, we may collect the following information you provide (collectively, the “User Content”):</p>
						<p>Information associated with messages and invitations that you provided to people in your network through the S4YCOMIC Games.</p>
						<p>In-game actions you make, links clicked, and other similar navigation action in connection with the S4YCOMIC Games (collectively, your “In-Game Actions”).</p>
						<p>Certain standard browser information, such as your browser type, IP address, and access times.</p>
						<p>Information from publicly accessible sources, such as blogs and comments from other users.</p>
						<p>In using and/or accessing the S4YCOMIC Games, you expressly authorize us to collect and use your Submitted Information and the User Content (collectively, the “Information”) in connection with the S4YCOMIC Games. You further acknowledge and agree that S4YCOMIC may, but is not obligated to, maintain copies of your Information indefinitely, or delete any or all Information, at our sole discretion.</p>
						<p>Notice to Residents of Countries Outside of Australia</p>
						<p>S4YCOMIC is headquartered in Australia. User Content may be accessed by us or transferred to us in Australia or to our affiliates, business partners, or service providers elsewhere in the world. By providing us with User Content, you consent to this transfer. We will protect the privacy and security of User Content according to our Privacy Notice, regardless of where it is processed or stored.</p>
						<p>Privacy Practices of Third Parties and Links to Other Websites</p>
						<p>This Privacy Notice only addresses the use and disclosure of information by S4YCOMIC through your interaction with the S4YCOMIC Games. Other websites that may be accessible through links from S4YCOMIC may have their own privacy statements and personal information collection, use, and disclosure practices. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party. We do not exercise control over third party websites. These other websites may place their own cookies (for a definition of “cookies”, please see the section “Use of Cookies” below) or other files on your computer, collect data or solicit personally identifiable information from you. Other sites follow different rules regarding the use or disclosure of the personally identifiable information you submit to them. We advise you to carefully read to the terms of use and privacy policies of SN Websites (collectively, the SNW Policies”) that you access the S4YCOMIC Games through. It is your responsibility to read and accept the respective SNW Policies prior to accessing and playing the S4YCOMIC Games.</p>
						<p>Use of Your Personal Information</p>
						<p>S4YCOMIC collects and uses your In-Game Actions to operate and improve the S4YCOMIC Games and better deliver the Services. These uses may include performing analysis aimed at improving the Service; making the S4YCOMIC Games or Services more user friendly; and displaying advertising and content that are tailored to your preferences and interests. S4YCOMIC will not use, display, or share any of your In-Game Actions or your Information in a manner inconsistent with the privacy settings on your Facebook Account and/or Section 9 of Facebook’s Statement of Rights and Responsibilities (currently accessible at http://www.facebook.com/terms.php).</p>
						<p>We may also use your Information (e.g., name, email address and other Information) to notify you of new releases, notifications, and to solicit your comments and feedback. Any such emails will include an “unsubscribe” link should you decide you do not want to receive further such emails.</p>
						<p>In-Game Actions and/or Information collected on S4YCOMIC sites and services may be stored and processed in Australia or any other country in which S4YCOMIC or its affiliates, subsidiaries or agents maintain facilities, and by using a S4YCOMIC site or service, you consent to any such transfer of information outside of your country.</p>
						<p>You understand and agree that S4YCOMIC may access, maintain, archive and disclose any Information we may have about you and the contents of your S4YCOMIC account if required to do so by law or in a good faith belief that such access, maintenance, archival or disclosure is reasonably necessary to comply with legal process (such as a search warrant, subpoena, statute, or court order), to avoid liability, or to protect our rights and property, or that of our affiliates or the public. S4YCOMIC is not required to question or contest the validity of any search warrant, subpoena or other similar governmental request that S4YCOMIC receives.</p>
						<p>Sharing of Your Personal Information</p>
						<p>Except as described in this Privacy Notice, your Information will be used only by S4YCOMIC and its controlled subsidiaries and affiliates, and such information will not be disclosed to any other third party without your consent. S4YCOMIC occasionally hires other companies to provide limited administrative and communication services on our behalf, such as providing customer support in connection with the S4YCOMIC Games, or performing statistical analysis of the Services. Those companies will be permitted to obtain only such Information from you as necessary for them to deliver the service. Those companies are required to maintain the confidentiality of the Information and are prohibited from using it for any other purpose.</p>
						<p>S4YCOMIC may access and/or disclose Information and/or In-Game Actions about you without your prior consent if S4YCOMIC has a good faith belief that such action is necessary to: (i) comply with legal process or other legal requirements of any governmental authority; (ii) protect and defend the rights or property of S4YCOMIC (including the enforcement of our agreements); (iii) operate or conduct maintenance and repair of the Services or equipment as authorized by law; (iv) act in urgent circumstances to protect the personal safety of users of S4YCOMIC Services or members of the public; or (v) act in accordance with the provisions set forth in Facebook’s Statement of Rights and Responsibilities, Principles, and any other Facebook policy in effect.</p>
						<p>In some cases, S4YCOMIC may choose to buy or sell assets. In these types of transactions, user information is typically one of the business assets that is transferred. Moreover, if S4YCOMIC, or substantially all of its assets, were acquired, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of S4YCOMIC may continue to use your Information as set forth in this Privacy Notice.</p>
						<p>Security of Your Personal Information</p>
						<p>The In-Game Actions S4YCOMIC collects is securely stored within our database, and we use standard, industry-wide practices such as firewalls, encryption, and (in certain areas) Secure Socket Layers for protecting your information. However, as effective as encryption technology is, no security system is impenetrable and S4YCOMIC cannot guarantee the security of our database.</p>
						<p>Collection and Use of Children's Personal Information</p>
						<p>The S4YCOMIC Games and the Service are intended for general audiences. S4YCOMIC does not knowingly collect or solicit personal information from anyone under the age of 16 or knowingly allow such persons to register. No one under age 16 may provide any personal information to or on S4YCOMIC. If you are under 16, please do not send any information about yourself to us (including, but not limited to, your name, address, telephone number, or email address) or register or attempt to register for a S4YCOMIC account or to access any S4YCOMIC Games. If a parent or guardian becomes aware that their child has provided us with personally identifiable information without the parent or guardian's consent, they should contact us at <a rel="noopener noreferrer" href="mailto:admin@s4ycomic.com">admin@s4ycomic.com</a>. If we become aware that a minor under 16 has provided us with personal identifiable information, we will use reasonable commercial efforts to promptly delete such information from our files.</p>
						<p>Use of Cookies and Related Technologies</p>
						<p>S4YCOMIC uses "cookies" to collect non-personal data and information. Cookies are small text files our S4YCOMIC Games uses to recognize repeat users, and is typically placed on your hard disk by a Web page server. Cookies contain information that can later be read by a web server in the domain that issued the cookie to you. The way cookies function is by assigning a number to the user that has no meaning outside of the assigning Web site.</p>
						<p>You should be aware that S4YCOMIC cannot control the use of cookies (or the resulting information) by S4YCOMIC’s third-party partners or advertisers. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to sign in or use other interactive features of S4YCOMIC sites and services that depend on cookies.</p>
						<p>In addition, S4YCOMIC may use third party analytics providers and products to obtain, compile and analyze Information (that may include Submitted Information) about how users are using and interacting with S4YCOMIC and/or the S4YCOMIC Games. S4YCOMIC may provide Information (that may include Submitted Information) to these analytics providers for the purpose of obtaining statistics and other information about how users are using and interacting with S4YCOMIC. These analytics providers may use a variety of established or new tracking technologies or tools (including, without limitation, cookies, web beacons, HTTP cache, local shared objects and persistent identifiers) to recognize your computer or device and/or to collect or compile this information. S4YCOMIC has no control over the technologies, tools or practices of the third parties that providing analytics products and services to S4YCOMIC.</p>
						<p>Comments Regarding this Privacy Notice</p>
						<p>S4YCOMIC welcomes your comments regarding this Privacy Notice. If you have questions regarding this Privacy Notice, please contact us by e-mail at <a rel="noopener noreferrer" href="mailto:admin@s4ycomic.com">admin@s4ycomic.com</a>.</p>
					</div>
				</div>
			</div>
		</div>;
	}
}